<template>
  <svg  @click="onClick(epackID)"  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6125 10.0724C15.8314 10.8534 14.5651 10.8534 13.784 10.0724C13.003 9.29132 13.003 8.02499 13.784 7.24395C14.5651 6.4629 15.8314 6.4629 16.6125 7.24395C17.3935 8.02499 17.3935 9.29132 16.6125 10.0724Z" fill="#8D9CA8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8656 2.00168C21.4098 2.00733 21.8496 2.44711 21.8552 2.99129C21.8912 6.47812 21.2437 9.4083 19.5857 11.7964C19.0126 12.6217 18.3294 13.3673 17.5325 14.0409C17.6332 14.6907 17.6412 15.3529 17.555 16.0074C17.4362 16.9097 17.1408 17.7799 16.6858 18.5681C16.2307 19.3563 15.6248 20.0472 14.9027 20.6012C14.1807 21.1553 13.3565 21.5617 12.4774 21.7973C12.2047 21.8703 11.9138 21.8247 11.6766 21.6717C11.4393 21.5187 11.2778 21.2726 11.2319 20.994L10.5263 16.7144L7.11787 13.3059L2.83831 12.6003C2.55976 12.5544 2.3136 12.3929 2.16059 12.1556C2.00759 11.9184 1.96199 11.6275 2.03506 11.3548C2.27062 10.4757 2.67704 9.65157 3.2311 8.92949C3.78517 8.20742 4.47603 7.60155 5.26425 7.14648C6.05246 6.6914 6.92259 6.39603 7.82496 6.27723C8.4926 6.18934 9.16821 6.19937 9.83059 6.30595C10.5002 5.51674 11.2409 4.83942 12.0601 4.27073C14.4483 2.61284 17.3786 1.96547 20.8656 2.00168ZM8.46837 8.22486C7.92684 9.13297 7.44446 10.1444 7.01472 11.2619L4.37945 10.8274C4.50638 10.5895 4.6529 10.3619 4.81781 10.147C5.21199 9.63332 5.70349 9.20228 6.26425 8.87853C6.825 8.55477 7.44404 8.34464 8.08601 8.26012C8.21315 8.24338 8.34071 8.23164 8.46837 8.22486ZM12.5755 16.8488L13.0048 19.4529C13.2427 19.3259 13.4703 19.1794 13.6852 19.0145C14.1989 18.6203 14.63 18.1288 14.9537 17.5681C15.2775 17.0073 15.4876 16.3883 15.5721 15.7463C15.5871 15.6323 15.5981 15.518 15.6051 15.4036C14.6987 15.9418 13.6897 16.4214 12.5755 16.8488ZM8.80956 12.1691L11.6871 15.0467C14.6928 13.9214 16.682 12.4717 17.9428 10.6558C19.143 8.92714 19.7529 6.76607 19.8444 4.01236C17.0906 4.10372 14.9293 4.71358 13.2006 5.91365C11.3846 7.17432 9.93487 9.16352 8.80956 12.1691Z" fill="#8D9CA8"/>
    <path d="M3.27204 20.7738C3.45961 20.9613 3.71403 21.0667 3.97929 21.0666L4.05238 21.0666C5.04701 21.0667 6.24884 21.0667 7.25579 20.6885C7.80203 20.4834 8.3205 20.1594 8.73775 19.653C9.1532 19.1488 9.42268 18.519 9.55912 17.7711C9.65824 17.2278 9.29815 16.707 8.75483 16.6079C8.21151 16.5087 7.69071 16.8688 7.5916 17.4122C7.50343 17.8955 7.35121 18.1906 7.19417 18.3812C7.03893 18.5697 6.83412 18.7105 6.55259 18.8162C6.13439 18.9733 5.62031 19.0322 4.99161 19.054C5.01341 18.4253 5.07234 17.9113 5.22941 17.4931C5.33515 17.2116 5.47599 17.0068 5.66443 16.8516C5.85506 16.6945 6.15027 16.5423 6.63359 16.4542C7.17691 16.355 7.53701 15.8342 7.43789 15.2909C7.33877 14.7476 6.81798 14.3875 6.27466 14.4866C5.52678 14.6231 4.89703 14.8925 4.39277 15.3079C3.88632 15.7251 3.56234 16.2436 3.35715 16.7898C2.97878 17.7971 2.97895 18.9993 2.9791 19.9943L2.9791 20.0665C2.97908 20.3318 3.08446 20.5862 3.27204 20.7738Z" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "Upload",
    props: {
      onClick: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
