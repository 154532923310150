<template>
  <vac-card :title="$t('Epacks List')">
    <div class="constructor-sort-panel row">
      <div class="flex xs12 md9 constructor-search-wrapper">
        <div class="constructor-search-button" @click="readItems(1)">
          <va-icon name="entypo entypo-search" />
        </div>
        <va-input
          label=""
          placeholder="Search E-packs"
          :value="epacksSearch"
          class="input--border"
          removable
          @input="setEpacksSearch"
          @keyup.enter="readItems(1)"
        />
      </div>
      <div class="flex xs6 md2">
        <va-select
          label="Per page"
          :options="perPageList"
          :value="epacksPerPage"
          @input="setEpacksPerPage"
          class="constructor-per-page-select"
          noClear
        />
      </div>
      <div class="flex xs6 md1">
        <div @click="fielsListPopupShow = true">
          <Eye />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="flex">
          <div class="constructor-action-buttons">
          <va-button
            :style="{
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: '18px',
            }"
            @click="$router.push('/panel/builder/untitled')"
          >
            Create New
          </va-button>
          <va-button
            :style="{
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              color: '#000000',
              background: '#ffffff',
            }"
            class="button--upload"
            @click="componentPopupShow(true)"
          >
            Upload
          </va-button>
        </div>
      </div>
      <div style="flex-grow: 1;"></div>

      <div class="flex" v-if="selectedEpacks.length">
        <va-button v-if="isAdminUser()" style="margin: 0;" @click="changeAuthorEpackagePopupShow = true">Change Author</va-button>
        <va-button v-if="isAdminUser()" style="margin: 0; margin-left: 18px;" @click="copyLocalePopupShow = true">Copy locales</va-button>
        <va-button outline class="btn--white" style="margin: 0; margin-left: 18px; border: 1px solid #d6dee2;" @click="selectedEpacks = []">Unselect All</va-button>
      </div>
      <div class="flex" v-else>
        <va-button outline class="btn--white" style="margin: 0; margin-left: 18px; border: 1px solid #d6dee2;" @click="selectAllEpackages">Select All</va-button>
      </div>
      <div class="flex">

        <div class="epacks-total" style="text-align: right;">
          Total Epacks: <b>{{totalEpacks}}</b>
          <div v-if="selectedEpacks.length">Selected: <b>{{selectedEpacks.length}}</b></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <!-- <double-scrollbar> -->
          <va-data-table
            :fields="computedFields"
            :data="formattedEpacksList"
            :loading="loading"
            :totalPages="totalPages"
            no-pagination
            @page-selected="readItems"
            class="epacks-list"
            api-mode
          >
            <template slot="selected" slot-scope="props">
              <div @click="selectedRowsUpdate" >
                <va-checkbox v-model="props.rowData.selected" />
              </div>
            </template>
            <template slot="locales" slot-scope="props">
              <div v-for="(locale,localeIdex) in Object.keys(props.rowData.locales)" :key="localeIdex" class="locale-item">
                <a 
                  class="loacale-text" 
                  target="_blank"
                  :href="`${backendUrl}/uploads/${props.rowData.packageId.toString()}/${locale}/minisite/master_template/content/iframe/`"
                >{{locale}}</a>
                <div class="templates">
                  <div v-for="(template,templateIndex) in props.rowData.locales[locale]" :key="`${localeIdex}-${templateIndex}`" class="template-item">{{template}}</div>
                </div>
              </div>
            </template>
            <template slot="templates" slot-scope="props">
              <div v-for="(template,index) in props.rowData.templates" :key="index">{{template}}</div>
            </template>
            <template slot="adminNote" slot-scope="props">
              <div
                class="constructor-icon"
                title="Epack was updated"
                @click="
                  isUpdatedStatusChange(
                    props.rowData.packageId,
                    !props.rowData.is_updated
                  )
                "
              >
                <ClientNoteActive v-if="props.rowData.is_updated" />
                <ClientNote v-else />
              </div>
            </template>
            <template slot="status" slot-scope="props">
              <div
                :class="[
                  'constructor-label',
                  'constructor-label-' + props.rowData.status,
                ]"
              ></div>
            </template>
            <template slot="link" slot-scope="props">
              <router-link
                :to="'/panel/builder/' + props.rowData.packageId"
                class="constructor-icon constructor-icon--view-icon"
                title="Edit Epack"
              >
                <Edit />
              </router-link>
            </template>
            <template slot="delete" slot-scope="props">
              <div
                class="constructor-icon"
                title="Delete Epack"
                @click="
                  deletePopupShow = true;
                  epackForDeleting = props.rowData.packageId;
                "
              >
                <Trash />
              </div>
            </template>
            <template slot="copy" slot-scope="props">
              <div
                class="constructor-icon"
                title="Copy Epack"
                @click="copyEpack(props.rowData.packageId)"
              >
                <Copy />
              </div>
            </template>
            <template slot="download" slot-scope="props">
              <DownloadEpackButton
                class="constructor-icon"
                title="Download Epack"
                :epackID="props.rowData.packageId.toString()"
              />
            </template>
            <!-- <template slot="preview" slot-scope="props">
              <a
                :href="`${backendUrl}/uploads/${props.rowData.packageId.toString()}/ru/minisite/master_template/content/iframe/`"
                target="_blank"
                class="constructor-icon"
              >
                <Eye/>
              </a>
            </template> -->
            <template slot="upload" slot-scope="props">
              <div
                title="Upload to Stream"
                @click="
                  isPublishPopupShow = true;
                  epackForPublishing = props.rowData.packageId.toString();
                "
                class="constructor-icon"
              >
                <Upload/>
              </div>
            </template>
          </va-data-table>
        <!-- </double-scrollbar> -->
        <div class="va-data-table__pagination">
          <va-pagination
            :value="currentPage"
            @input="setCurrentPage"
            :visible-pages="5"
            :pages="totalPages"
          />
        </div>
      </div>
    </div>
    <UploadEpackPopup
      v-if="isUploadEpackPopupShow"
      @componentPopupShow="componentPopupShow"
      @uploadEpack="uploadEpack"
    />
    <va-modal
      v-model="deletePopupShow"
      size="medium"
      title="Delete E-package"
      message="Do you really want to delete this E-package?"
      okText="Delete"
      cancelText="Cancel"
      @ok="deleteEpack(epackForDeleting)"
    />

    <va-modal
      v-model="isPublishPopupShow"
      size="medium"
      title="Upload to Stream"
      message="This action will upload E-package to Stream."
      okText="Upload"
      cancelText="Cancel"
      @ok="uploadEpackToPanelHandler({epackID: epackForPublishing, isOverwriting});"
    >
    <va-checkbox v-if="isAdminUser()" label="Overwrite current" v-model="isOverwriting"  />
    </va-modal>

    
    <va-modal
      v-model="copyLocalePopupShow"
      size="medium"
      title="Copy Locale"
      message="Choose Source locale and new locale"
      okText="Copy Locale"
      cancelText="Cancel"
      @ok="copyLocaleMassHandler"
    >
      <template slot="default">
        <div class="layout fluid gutter--md">
        
          <SelectAutocomplete
            class="input--epack-data"
            label="Source"
            v-model="srcLocale"
            :limit="10"
            noClear
            :options="srcLocalesList"
            searchable
          />
        
      </div>
        <div class="layout fluid gutter--md">
          <div class="row">
            <div class="flex xs12 sm6">
            <va-select
              :label="$t('constructor.Country')"
              v-model="countryVal"
              icon="search"
              :options="countryList"
            />
            </div>
            <div class="flex xs12 sm6">
            <va-select
              :label="$t('constructor.Language')"
              v-model="languageVal"
              icon="search"
              :options="languageList"
            />
            </div>
          </div>
        </div>
        <!-- <SelectAutocomplete
          class="input--epack-data"
          label="Destination"
          v-model="distLocale"
          :limit="10"
          noClear
          :options="distLocalesList"
          searchable
        /> -->
        
      </template>
    </va-modal>

    <va-modal
      v-if="isAdminUser()"
      v-model="changeAuthorEpackagePopupShow"
      size="medium"
      title="Change author"
      message="Choose new author for Epackages"
      okText="Change Author"
      cancelText="Cancel"
      @ok="changeAuthorMassHandler"
    >
      <template slot="default">
        <SelectAutocomplete
          class="input--epack-data"
          label="Owner"
          v-model="epacksOwner"
          :limit="10"
          noClear
          :options="usersListFormatted"
          searchable
        />

      </template>
    </va-modal>

    <va-modal
      v-model="fielsListPopupShow"
      size="medium"
      title="Select Fields"
      :hideDefaultActions="true"
    >
      <template slot="default" v-if="fields.length">
        <div v-for="(field, index) in fields" :key="index">
          <va-checkbox
            v-if="field.title !== ''"
            v-model="field.isShow"
            class="mb-1"
            :label="field.title"
          />
        </div>
      </template>
    </va-modal>

    <va-modal
      v-model="chooselicensePopupShow"
      size="medium"
      :title="$t('Choose license')"
      cancelText=""
      okText="Add"
      @ok="uploadEpackToPanelHandler({epackID: epackForPublishing, license: licenseVal, isOverwriting}); "
    >
      <div class="layout fluid gutter--md">
        <div class="row">
          <div class="flex xs12">
            <va-select
              :label="$t('License')"
              v-model="license"
              :options="licensesList.map(license => (license.name))"
              class="choose-license"
              noClear
            />
          </div>
        </div>
      </div>
    </va-modal>
  </vac-card>
</template>

<script>
import dcopy from "deep-copy";
import { mapActions, mapState, mapMutations } from "vuex";

import APIConstructor from "../../services/API/APIConstructor";
import Edit from "../ui/icons/constructor/Edit.vue";
import Copy from "../ui/icons/constructor/Copy.vue";
import Trash from "../ui/icons/constructor/Trash.vue";
import Upload from "../ui/icons/constructor/Upload.vue";
import Eye from "../ui/icons/Eye.vue";
import ClientNote from "../ui/icons/constructor/ClientNote.vue";
import ClientNoteActive from "../ui/icons/constructor/ClientNoteActive.vue";

import DownloadEpackButton from "./DownloadEpackButton.vue";
import UploadEpackPopup from "./UploadEpackPopup.vue";
import { showToastError } from "../../services/Helpers/HelperToast";

import SelectAutocomplete from "../ui/SelectAutocomplete/SelectAutocomplete";
import countryList from "../../data/constructor/countries.json";
import languageList from "../../data/constructor/languages.json";
// import DoubleScrollbar from "../ui/DoubleScrollbar.vue";

export default {
  name: "EpacksList",
  components: {
    Copy,
    Edit,
    Trash,
    Upload,
    Eye,
    ClientNoteActive,
    ClientNote,
    UploadEpackPopup,
    DownloadEpackButton,
    SelectAutocomplete,
    // DoubleScrollbar,
  },
  data() {
    return {
      // perPage: "10",
      backendUrl: process.env.VUE_APP_CONSTRUCTOR_URL,
      totalPages: 0,
      totalEpacks: 0,
      epacksList: [],
      loading: false,
      isUploadEpackPopupShow: false,
      isPublishPopupShow: false,
      deletePopupShow: false,
      changeAuthorEpackagePopupShow: false, 
      copyLocalePopupShow: false, 
      epackForDeleting: null,
      epackForPublishing: null,
      perPageList: ["10", "25", "50", "100"],
      countryList: countryList,
      languageList: languageList,
      countryVal: null,
      languageVal: null,
      selectedEpacks: [],
      epacksOwner: null,
      fielsListPopupShow: false,
      isOverwriting: false,
      license: "",
      licenseVal: "",
      fields: [
        {
          name: "__slot:selected",
          title: "",
          width: '20px',
          isShow: true,
        },
        {
          name: "name",
          title: "Name",
          width: "250px",
          isShow: true,
        },
        {
          name: "__slot:locales",
          title: "Locales",
          width: '200px',
          isShow: true,
        },
        // {
        //   name: "__slot:templates",
        //   title: "Templates",
        //   width: "200px",
        // },
        {
          name: "packageId",
          title: "epack ID",
          width: "200px",
          isShow: true,
        },
        {
          name: "creater",
          title: "Author",
          width: "150px",
          isShow: true,
        },
        {
          name: "updater",
          title: "Updated by",
          width: "150px",
          isShow: true,
        },
        {
          name: "brandname",
          title: "Brand",
          width: "150px",
          isShow: true,
        },

        {
          name: "__slot:status",
          title: "",
          width: "15px",
          isShow: true,
        },
        {
          name: "date_updated.date",
          title: "Updated",
          width: "150px",
          isShow: true,
        },
        {
          name: "mpn",
          title: "MPN",
          width: "150px",
          isShow: true,
        },
        {
          name: "ean",
          title: "EAN",
          width: "150px",
          isShow: true,
        },
        {
          name: "__slot:link",
          title: "",
          width: "20px",
          isShow: true,
        },
        {
          name: "__slot:delete",
          title: "",
          width: "20px",
          isShow: true,
        },
        {
          name: "__slot:copy",
          title: "",
          width: "20px",
          isShow: true,
        },
        {
          name: "__slot:download",
          title: "",
          width: "20px",
          isShow: true,
        },
        // {
        //   name: "__slot:preview",
        //   title: "",
        //   width: "20px",
        //   isShow: true,
        // },
      ],
      // search: "",
      srcLocale: "",
      // distLocale: "",
      distLocalesList: [],
    };
  },
  computed: {
    ...mapState({
      currentPage: (state) => state.constructorData.currentPage,
      epacksPerPage: (state) => state.constructorData.epacksPerPage,
      epacksSearch: (state) => state.constructorData.epacksSearch,
      currentUserPermissionsArray: (state) => state.currentUserProfilePermissions,
      usersList: (state) => state.constructorData.usersList,
      licensesList: (state) => state.constructorData.licensesList,
    }),
    formattedEpacksList() {
      const outputArr = [];
      this.epacksList.map((dataRow) => {
        const epack = { ...dataRow };
        const isEpackSelected = this.selectedEpacks.find(epack => epack === dataRow.packageId);
        epack.selected = isEpackSelected ? true : false;
        epack.date_created.date = this.formatDate(dataRow.date_created.date);
        epack.date_updated.date = this.formatDate(dataRow.date_updated.date);
        outputArr.push(epack);
      });
      return outputArr;
    },
    usersListFormatted() {
      const usersListFormatted = this.usersList.map(elem => elem.username);
      return usersListFormatted;
    },
    computedFields: function () {
      const updatedFields = dcopy(this.fields);
      if (this.isAdminUser()) {
        const elemPosition = this.fields.findIndex(
          (elem) => elem.name === "__slot:link"
        );
        const elemForAdding = {
          name: "__slot:adminNote",
          title: "",
          width: "30px",
          isShow: true,
        };
        updatedFields.splice(elemPosition, 0, elemForAdding);
      }
      if (this.isAdminUser() || this.checkUserPermission('Publish_stream')) {
        const elemForAdding = {
          name: "__slot:upload",
          title: "",
          width: "20px",
          isShow: true,
        };
        updatedFields.splice(-1, 0, elemForAdding);
      }
      return updatedFields.filter(field => field.isShow === true);
    },
    chooselicensePopupShow: {
      get() {
        return this.licensesList.length ? true : false;
      },
      set(val) {
        if(val === false) {
          this.setLicensesList([]);
        }
      }
    },
    srcLocalesList() {
      const localesList = [];
      const selectedEpacks = this.formattedEpacksList.filter(row => row.selected === true);
      selectedEpacks.forEach(epack => {
        Object.keys(epack.locales).forEach(locale => {
          if(localesList.indexOf(locale) === -1) {
            localesList.push(locale);
          }
        });
      });
      return localesList;
    },
    distLocale() {
      return this.countryVal+'_'+this.languageVal;
    },
  },
  created() {
    this.getSearchData();
    // this.perPage = this.epacksPerPage;
    this.readItems(this.currentPage);
    if(this.isAdminUser()) {
      this.getUsersList();
    }
  },
  methods: {
    ...mapActions("constructorData", [
      "setCurrentPage",
      "setEpacksPerPage",
      "setEpacksSearch",
      "uploadEpackToPanel",
      "getUsersList",
      "changeAuthorMass",
      "copyLocaleMass",
    ]),
    ...mapMutations("constructorData", [
      "setLicensesList",
    ]),
    readItems (page = 1) {
      this.loading = true;
      // this.currentPage = page;
      this.setCurrentPage(page);
      const params = {
        limit: this.epacksPerPage,
        page: page,
      };
      if (this.epacksSearch.length) {
        params.search = this.epacksSearch;
        if (this.epacksSearch !== this.$route.query.search) {
          this.$router.push({
            path: "/panel/builder",
            query: { search: this.epacksSearch },
          });
        }
      } else {
        if (
          this.$route.query.search !== "" &&
          this.$route.query.search !== undefined
        ) {
          this.$router.push({ path: "/panel/builder" });
        }
      }
      APIConstructor.APIGet(
        `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/epacks`,
        { params },
        (response) => {
          if (response.code === 200) {
            this.epacksList = response.data;
            this.totalPages = response.nav.total;
            this.totalEpacks = response.nav.epackages;
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this.$toast
            );
          }
          this.loading = false;
        },
        () => {
          showToastError(
            "Something wrong. <br/> Please try later",
            this.$toast
          );
          this.loading = false;
        }
      );
    },
    deleteEpack (epackID) {
      this.loading = true;
      APIConstructor.APIDelete(
        `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/delete/${epackID}`,
        {},
        (response) => {
          if (response.code === 200) {
            this.readItems(this.currentPage);
            this.epackForDeleting = null;
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this.$toast
            );
          }
          this.loading = false;
        },
        () => {
          showToastError(
            "Something wrong. <br/> Please try later",
            this.$toast
          );
          this.loading = false;
        }
      );
    },
    copyEpack (epackID) {
      this.loading = true;
      APIConstructor.APIPost(
        `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/copy/${epackID}`,
        {},
        (response) => {
          if (response.code === 200) {
            const newEpackID = response.manifest.packageID;
            this.$router.push("/panel/builder/" + newEpackID);
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this.$toast
            );
          }
          this.loading = false;
        },
        () => {
          showToastError(
            "Something wrong. <br/> Please try later",
            this.$toast
          );
          this.loading = false;
        }
      );
    },
    isUpdatedStatusChange (epackID, status) {
      this.loading = true;
      APIConstructor.APIPost(
        `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/status/${epackID}`,
        { status },
        (response) => {
          if (response.code === 200) {
            const elemId = this.epacksList.findIndex(
              (elem) => elem.packageId === epackID
            );
            this.epacksList[elemId].is_updated = status;
          } else if (response.code === 402) {
            showToastError(response.message, this.$toast);
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this.$toast
            );
          }
          this.loading = false;
        },
        () => {
          showToastError(
            "Something wrong. <br/> Please try later",
            this.$toast
          );
          this.loading = false;
        }
      );
    },
    componentPopupShow (isShown) {
      this.isUploadEpackPopupShow = !!isShown;
    },
    uploadEpack (epack, packageID) {
      this.componentPopupShow(false);
      let epackFormData = new FormData();
      epackFormData.append("file", epack);
      if (packageID.trim() !== "") {
        epackFormData.append("number", packageID.trim());
      }
      this.loading = true;
      APIConstructor.APIPost(
        `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/new/epack`,
        epackFormData,
        (response) => {
          if (response.code === 200) {
            this.$router.push(
              "/panel/builder/" + response.manifest.packageID
            );
            // this.readItems(1);
          } else if (response.code === 402) {
            showToastError(response.message, this.$toast);
            this.loading = false;
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this.$toast
            );
          }
          this.loading = false;
        },
        (error) => {
          if (error.response.status === 402) {
            showToastError(error.response.data.data.message, this.$toast);
          } else {
            showToastError(
              "Something wrong. <br/> Please try later",
              this.$toast
            );
          }
          this.loading = false;
        }
      );
    },
    changePublishingStatus(packageId) {
      this.epacksList.map((elem) => {
        if (elem.packageId === packageId) {
          elem.status = 2;
        }
      });
    },
    formatDate (inputDate) {
      const date = new Date(inputDate);
      return `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    },
    getSearchData () {
      if (this.$route.query && this.$route.query.search) {
        this.setEpacksSearch(this.$route.query.search);
        // this.search = this.$route.query.search;
      }
    },
    isAdminUser () {
      const userData = JSON.parse(localStorage.getItem("ttlUserInfo"));
      if (!userData.roles) {
        return false;
      } else {
        return userData.roles.includes("ROLE_ADMIN")
      }
    },
    checkUserPermission(permission) {
      const currentPermissionArray = this.currentUserPermissionsArray.filter(elem => elem.name === permission);
      return currentPermissionArray.length ? true : false;
    },
    selectedRowsUpdate() {
      const selectedEpacks = this.formattedEpacksList.filter(row => row.selected === true);
      const selectedEpacksIds = selectedEpacks.map(row => row.packageId);
      this.selectedEpacks = selectedEpacksIds;
    },
    async changeAuthorMassHandler() {
      try {
        await this.changeAuthorMass({author: this.epacksOwner,epacksList: this.selectedEpacks})
        this.formattedEpacksList.forEach(epack => {
          if(this.selectedEpacks.includes(epack.packageId)) {
            epack.creater = this.epacksOwner;
          }
        });
      } catch (e) {

      }
    },

    async copyLocaleMassHandler() {
      try {
        await this.copyLocaleMass({src: this.srcLocale, dist: this.distLocale ,epacksList: this.selectedEpacks})
        this.srcLocale = "";
        this.countryVal = null;
        this.languageVal = null;
        this.selectedEpacks = [];
        setTimeout(() => {
           this.readItems(this.currentPage);
        },500);
      } catch (e) {

      }
    },
    selectAllEpackages() {
      this.selectedEpacks = this.formattedEpacksList.map(epack => epack.packageId);
    },
    updateTableCols() {
      localStorage.setItem('tableCols', JSON.stringify(this.fields));
    },
    async uploadEpackToPanelHandler({epackID, license}) {
      this.loading = true;
      this.uploadEpackToPanel({epackID, license}).then(result => {
        console.log(result);
        this.loading = false;
        if (result.success) {
          this.changePublishingStatus(epackID);
        }
      }).catch(e => {
        console.log(e);
        this.loading = false;
      });
      // this.loading = false;
      // if (result?.code === 200) {
      //   changePublishingStatus(epackID);
      // }
    },
  },
  watch: {
    epacksPerPage: function () {
      this.readItems(1);
    },
    currentPage: function () {
      this.readItems(this.currentPage);
    },
    fields: {
      handler() {
        this.updateTableCols();
      },
      deep: true,
    },
    license() {
      if(this.license !== '') {
        const licenseElem = this.licensesList.find(license => (license.name === this.license));
        this.licenseVal = licenseElem?.id;
      }
    }
  },
  mounted() {
    const tableColsString = localStorage.getItem('tableCols');
    if (tableColsString) {
      const tableCols = JSON.parse(tableColsString);
      tableCols.forEach(storageElem => {
        if(storageElem.title !== '') {
          const field = this.fields.find(fieldElem => (
            fieldElem.title === storageElem.title
          ));
          field.isShow = storageElem.isShow;
        }
      })
      // this.fields = JSON.parse(localStorage.getItem('tableCols'));
    }
  },
};
</script>

<style lang="scss">
body .button--cancel {
  background: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #d6dee2;

  &:hover {
    background: #e3e9ed !important;
    opacity: 1;
    filter: none;
    border: 1px solid #d6dee2;
  }
}

body .input--epack-data {
  border: 1px solid #d6dee2 !important;
}

.dropzone--white {
  padding: 1.5rem 0.5rem 0.5rem;

  .va-file-upload__field__button {
    background: #e3e9ed !important;
    color: #000000 !important;
    border: none;
  }

  .va-file-upload__field {
    padding: 0 0.5rem 1rem;
    text-align: center;
  }

  &.dropzone--vertical {
    .va-file-upload__field__text {
      padding-right: 0;
      padding-bottom: 10px;
    }
  }
}

.constructor-action-buttons {
  display: flex;

  .button--upload {
    background: #ffffff !important;
    color: #000000;
    border: 1px solid #d6dee2;

    &:hover {
      background: #e3e9ed !important;
      opacity: 1;
      filter: none;
      border: 1px solid #d6dee2;
    }
  }
}

.constructor-sort-panel {
  display: flex;
  align-items: center;
}

.constructor-search-wrapper {
  display: flex;
  margin-top: 0.5rem;
}

.constructor-search-button {
  color: #c4d0da;
  background: none;
  border-bottom: 1px solid #d6dee2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 40px;
  padding-left: 4px;
  padding-right: 20px;
  margin-bottom: 1rem;
}

.constructor-icon {
  cursor: pointer;

  svg {
    transition: 0.2s;

    // path {
    //   fill: rgb(141, 156, 168);
    // }

    &:hover {
      path {
        fill: #000000;
      }
    }
  }
}

.constructor-label {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 auto;

  &-0 {
    background: #c4d0da;
  }

  &-1 {
    background: #f3972b;
  }

  &-2 {
    background: #45db54;
  }
}

.download-epack-button {
  padding: 0;
}

// .epacks-total {
// padding: 0 1.5rem;
// }

.constructor-icon--view-icon {
  svg {
    &:hover {
      path {
        fill: none;
        stroke: #000000;
      }
    }
  }
}

.epacks-list {
  width: 100%;

  .inner-loading {
    width: max-content;
  }

  .locale-item {
    display: flex;
    margin-bottom: 4px;
  }
  .loacale-text {
    min-width: 40px;
    font-weight: bold;
    padding-right: 4px;
    color: #000000;
    text-decoration: underline;
  }
}
</style>
