<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7431 2C18.0227 2 18.2896 2.11705 18.4789 2.32276L21.7358 5.86108C21.9057 6.04568 22 6.28742 22 6.53832V17C22 17.5523 21.5523 18 21 18H16V16H20V8H17C15.8954 8 15 7.10457 15 6V4H12V5H10V3C10 2.44772 10.4477 2 11 2H17.7431ZM17.3045 4H17V6H19.1454L17.3045 4Z" fill="#8D9CA8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4789 6.32276C10.2896 6.11705 10.0227 6 9.74314 6H3C2.44771 6 2 6.44772 2 7V21C2 21.5523 2.44772 22 3 22H13C13.5523 22 14 21.5523 14 21V10.5383C14 10.2874 13.9057 10.0457 13.7358 9.86108L10.4789 6.32276ZM4 20H12V12H9C7.89543 12 7 11.1046 7 10V8H4V20ZM9.30447 8L11.1454 10H9V8H9.30447Z" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "Copy",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
