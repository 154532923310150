<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1566 5.68683H5.59035C5.16857 5.68683 4.76405 5.85438 4.4658 6.15263C4.16755 6.45088 4 6.85539 4 7.27718V18.4097C4 18.8314 4.16755 19.236 4.4658 19.5342C4.76405 19.8324 5.16857 20 5.59035 20H16.7228C17.1446 20 17.5491 19.8324 17.8474 19.5342C18.1456 19.236 18.3132 18.8314 18.3132 18.4097V12.8434M17.1205 4.49406C17.4368 4.17772 17.8659 4 18.3132 4C18.7606 4 19.1897 4.17772 19.506 4.49406C19.8223 4.8104 20.0001 5.23945 20.0001 5.68682C20.0001 6.1342 19.8223 6.56325 19.506 6.87959L11.9518 14.4338L8.77112 15.2289L9.56629 12.0482L17.1205 4.49406Z" stroke="#8D9CA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: "Edit",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
