<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4444 10.4L17.4444 14.0688L18.7721 15.4344C18.8445 15.5086 18.9019 15.5967 18.941 15.6938C18.9801 15.7909 19.0001 15.8949 19 16L19 16.8C19 17.0122 18.9181 17.2157 18.7722 17.3657C18.6263 17.5157 18.4285 17.6 18.2222 17.6H5.77778C5.5715 17.6 5.37367 17.5157 5.22781 17.3657C5.08194 17.2157 5 17.0122 5 16.8V16C4.99985 15.8949 5.01991 15.7909 5.05902 15.6938C5.09813 15.5967 5.15552 15.5086 5.22789 15.4344L6.55556 14.0688L6.55556 10.4C6.55556 7.8264 8.255 5.6592 10.5572 5.0064C10.7851 4.416 11.342 4 12 4C12.658 4 13.2149 4.416 13.4428 5.0064C15.745 5.6584 17.4444 7.8264 17.4444 10.4ZM13.3444 19.5601C12.9516 19.8469 12.4817 20.0006 12 20C11.5183 20.0006 11.0484 19.8469 10.6556 19.5601C10.2628 19.2734 9.96663 18.8679 9.80822 18.4H14.1918C14.0334 18.8679 13.7372 19.2734 13.3444 19.5601ZM15.802 14.4731L16.8587 15.56L7.14111 15.5599L8.19777 14.4731C8.42588 14.2394 8.551 13.9248 8.551 13.5999V10.3999C8.551 8.42557 10.1102 6.83997 11.9999 6.83997C13.8896 6.83997 15.4488 8.4256 15.4488 10.4V13.6C15.4488 13.9248 15.5739 14.2395 15.802 14.4731Z" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "ClientNote",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
