<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#8D9CA8" d="M21.8661 11.5043C21.2263 10.3943 17.7077 4.82427 11.7301 5.00427C6.2023 5.14427 3.00358 10.0043 2.13392 11.5043C2.04619 11.6563 2 11.8287 2 12.0043C2 12.1798 2.04619 12.3522 2.13392 12.5043C2.76367 13.5943 6.13233 19.0043 12.02 19.0043H12.2699C17.7977 18.8643 21.0064 14.0043 21.8661 12.5043C21.9538 12.3522 22 12.1798 22 12.0043C22 11.8287 21.9538 11.6563 21.8661 11.5043ZM12.2199 17.0043C7.91163 17.1043 5.10274 13.4143 4.22309 12.0043C5.22269 10.3943 7.83166 7.10427 11.8301 7.00427C16.1184 6.89427 18.9372 10.5943 19.8269 12.0043C18.7973 13.6143 16.2183 16.9043 12.2199 17.0043Z"></path>
    <path fill="#8D9CA8" d="M11.9998 8.50427C11.3079 8.50427 10.6315 8.70954 10.0561 9.09413C9.48077 9.47871 9.03234 10.0253 8.76754 10.6649C8.50274 11.3044 8.43345 12.0082 8.56845 12.6871C8.70344 13.366 9.03665 13.9897 9.52594 14.4791C10.0152 14.9686 10.6386 15.302 11.3173 15.437C11.996 15.5721 12.6994 15.5028 13.3387 15.2379C13.978 14.9729 14.5244 14.5243 14.9088 13.9488C15.2933 13.3732 15.4984 12.6965 15.4984 12.0043C15.4984 11.076 15.1298 10.1858 14.4737 9.5294C13.8176 8.87302 12.9277 8.50427 11.9998 8.50427ZM11.9998 13.5043C11.7033 13.5043 11.4134 13.4163 11.1668 13.2515C10.9202 13.0867 10.728 12.8524 10.6146 12.5783C10.5011 12.3042 10.4714 12.0026 10.5292 11.7116C10.5871 11.4207 10.7299 11.1534 10.9396 10.9436C11.1493 10.7338 11.4165 10.591 11.7073 10.5331C11.9982 10.4752 12.2996 10.5049 12.5736 10.6185C12.8476 10.732 13.0818 10.9242 13.2465 11.1709C13.4113 11.4176 13.4992 11.7076 13.4992 12.0043C13.4992 12.4021 13.3413 12.7836 13.0601 13.0649C12.7789 13.3462 12.3975 13.5043 11.9998 13.5043Z"></path>
  </svg>
</template>

<script>
  export default {
    name: "Eye",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
