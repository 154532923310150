<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 16V19H5V16H3V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V16H19ZM17 10L15.59 8.59L13 11.17V3H11V11.17L8.41 8.59L7 10L12 15L17 10Z" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "Download",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
