<template>
  <div
    class="va-modal__overlay"
    style="background-color: rgba(0, 0, 0, 0.6);"
    @click.self="componentPopupShow(false)"
  >
    <div class="va-modal va-modal--mobile-fullscreen">

      <div class="va-modal__inner">
        <div
          class="mb-4 title"
          style="color: rgb(0, 0, 0);"
        >Upload Epack</div>

        <div class="mb-4 va-modal__message">
          <va-file-upload
            type="single"
            class="dropzone--white"
            :style="{background: '#ffffff', border: '2px dashed #D6DEE2'}"
            dropzone
            v-model="epack"
          />
        </div>
        <div class="mb-4 va-modal__message">
          <va-input
            label="Custom package ID"
            v-model="customPackageId"
            class="input--epack-data"
          />
        </div>
        <div class="va-modal__actions mb-3">
          <va-button
            @click="uploadEpack"
            style="margin-right: 18px;"
          >
            Upload
          </va-button>
          <va-button
            class="button--cancel"
            @click="componentPopupShow(false)"
          >
            Cancel
          </va-button>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "UploadEpackPopup",
  data() {
    return {
      epack: [],
      customPackageId: '',
    };
  },
  methods: {
    componentPopupShow: function (isShown) {
      this.$emit("componentPopupShow", isShown);
    },
    uploadEpack: function () {
      this.$emit("uploadEpack", this.epack[this.epack.length - 1],this.customPackageId);
    },
  },
};
</script>
