<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20C12.4817 20.0006 12.9516 19.8469 13.3444 19.5601C13.7372 19.2734 14.0334 18.8679 14.1918 18.4H9.80822C9.96663 18.8679 10.2628 19.2734 10.6556 19.5601C11.0484 19.8469 11.5183 20.0006 12 20ZM17.4444 14.0688V10.4C17.4444 7.8264 15.745 5.6584 13.4428 5.0064C13.2149 4.416 12.658 4 12 4C11.342 4 10.7851 4.416 10.5572 5.0064C8.255 5.6592 6.55556 7.8264 6.55556 10.4V14.0688L5.22789 15.4344C5.15552 15.5086 5.09813 15.5967 5.05902 15.6938C5.01991 15.7909 4.99985 15.8949 5 16V16.8C5 17.0122 5.08194 17.2157 5.22781 17.3657C5.37367 17.5157 5.5715 17.6 5.77778 17.6H18.2222C18.4285 17.6 18.6263 17.5157 18.7722 17.3657C18.9181 17.2157 19 17.0122 19 16.8V16C19.0001 15.8949 18.9801 15.7909 18.941 15.6938C18.9019 15.5967 18.8445 15.5086 18.7721 15.4344L17.4444 14.0688Z" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "ClientNoteActive",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
