<template>
  <button
    :disabled="disabled"
    :style="{ marginTop: 0, marginBottom: 0 }"
    @click="downloadEpack"
    :class="computedClass"
  >
    <Download/>
  </button>
</template>
<script>
import APIConstructor from "../../services/API/APIConstructor";
import { showToastError } from "../../services/Helpers/HelperToast";

import Download from "../ui/icons/constructor/Download";

export default {
  name: "DownloadEpackButton",
  components: {
    Download,
  },
  props: {
    epackID: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedClass: function () {
      return (
        (this.className.trim().length ? this.className.trim() + " " : "") + "download-epack-button"
      );
    },
  },
  methods: {
    downloadEpack: function () {
      this.loading = false;
      APIConstructor.APIPost(
        `${process.env.VUE_APP_CONSTRUCTOR_API_URL}/download/${this.epackID}`,
        {},
        ({ epack }) => {
          let link = process.env.VUE_APP_CONSTRUCTOR_URL + epack;
          this.loading = false;
          const fileLink = document.createElement("a");
          fileLink.setAttribute("href", link);
          fileLink.setAttribute("rel", "noopener noreferrer");
          fileLink.setAttribute("download", "");
          document.body.appendChild(fileLink);
          fileLink.click();
          // this.isSaved = true;
          // this.clearTempEpack();
        },
        () => {
          showToastError(
            "Something wrong. <br/> Please try later",
            this.$toast,
          );
          this.loading = false;
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.download-epack-button {
  border: none;
  background: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }

  &.va-button {
    border: 1px solid #000000;
  }

  i {
    display: block;
  }
}
</style>
